<template>
	<div class="row">
		<div class="col-12">
            <h4>{{ $t('trigger.entity_type.solde_contract') }}</h4>
            <h5>{{ $t('acte.trigger.solde_contract_select') }}</h5>
        </div>
        <div class="col-12">
			<CustomTable
				ref="table"
				id_table="trigger_solde_contract"
				:items="contracts"
				primaryKey="avenantarticles_id"
				:useTransformerSort="true"
				:buttonActionEventOnly="true"
				:busy.sync="table_busy"
			/>
        </div>
        <div class="text-center mt-3 col-12">
            <button @click="closeShutter" class="btn btn-secondary rounded-pill mr-4">
                <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}
            </button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js";
    import ActesMixin from "@/mixins/Actes.js";
    import ContractMixin from "@/mixins/Contract.js";
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'

	export default {
		name: 'SoldeContract',
		mixins: [Navigation, ActesMixin, ContractMixin, Shutter, ShutterTriggers],
		props: {
			horses_ids: { type: Array, default: () => [] },
			actes_ids: { type: Array, default: () => [] },
			couples: { type: Array, default: () => [] },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
		},
		data () {
			return {
				contracts: [],
				table_busy: false,
				events_tab: {
					'TableAction::DeleteSoldeContract::Trigger': this.deleteSoldeContract,
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				if(this.actes_ids.length > 0) {
					const acte = await this.getActeFromId(this.actes_ids[0])
					const rep_sms = acte.reponse.reponse_hidden_data.find(rep => rep && rep.type == 'season_mare_stallion')
					if(!rep_sms) {
						this.closeShutter()
						return false
					}
					const sms = rep_sms.id
					this.contracts = await this.getContractBySeasonMareStallion([sms])
				}
				else if(this.couples.length > 0) {
					this.contracts = await this.getContractBySeasonMareStallion(this.couples.map(sms => sms.seasonmarestallion_id))
				}
				this.table_busy = false
			},

			async deleteSoldeContract(articles) {
				const articles_ids = articles.map(a => a.avenantarticles_id)
				const contract_ids = articles.map(a => a.contract_id)

				await this.deleteContractAvenantsArticles(articles_ids, contract_ids)
				await this.updateContract(contract_ids)

				EventBus.$emit('TableAction::stopSpin', true)
				this.closeShutter()
			},

			closeShutter() {
				if(this.couples.length > 0) {
					this.$emit('submit')
				}
				else if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('solde_contract')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                } else { 
                    let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                    this.$router.push({ name: 'HorseFichePlanning', params: {horse_id} })
                }
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>